.qr-scanner-modal .ant-modal-content {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
}

.qr-scanner-modal .ant-modal-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.qr-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    max-width: 500px;
    max-height: 500px;
    aspect-ratio: 1;
    transform: translate(-50%, -50%);
    border: 4px solid rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    pointer-events: none;
}